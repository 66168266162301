/* eslint-disable max-lines-per-function */
/* eslint-disable no-magic-numbers */
import {
  memo, useEffect, useState
} from "react";
import { divIcon } from "leaflet";
import { renderToString } from "react-dom/server";
import { Marker } from "react-leaflet";

import { getIconHtml } from "~/src/features/maps/projects-map/map-locations/helpers.js";
import ProjectMarkerPopup from "~/src/features/project-marker-popup.jsx";
import useProject from "~/src/hooks/use-project.js";
import useStore from "~/src/hooks/use-store.js";
import MapMarker from "~/src/ui/markers/map-marker.jsx";

const ProjectMarker = ({
  point,
  point: {
    geometry: {
      coordinates: [
        lng,
        lat
      ]
    },
    properties: {
      slug,
      currentMarketingPhase,
      currentConstructionPhase,
      selected,
      unselected
    }
  },
  simple,
  map,
  isSimpleMap
}) => {
  const [
    opened,
    setOpened
  ] = useState(false);

  const [
    icon,
    setIcon
  ] = useState(null);

  const mainSelectionType = useStore((state) => state.mainSelectionType);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setChange = useStore((state) => state.setChange);

  const [
    currentSelected,
    setCurrentSelected
  ] = useState(true);
  const [
    checkboxDisabled,
    setCheckboxDisabled
  ] = useState(false);

  useEffect(() => {
    if (mainSelectionType === "addAll") {
      setCurrentSelected(!unselected);
    }
    else if (mainSelectionType === "removeAll") {
      setCurrentSelected(Boolean(selected));
    }
  }, [
    selected,
    unselected,
    mainSelectionType
  ]);

  const {
    project, isLoading, isError, mutate
  } = useProject(opened ? slug : null);

  useEffect(() => {
    const markerHtml = simple
      ? renderToString(<MapMarker className="w-full h-full text-marker-blue" icon="circle" />)
      : renderToString(getIconHtml({
        currentMarketingPhase,
        currentConstructionPhase,
        selected: currentSelected
      }));

    setIcon(divIcon({
      html: markerHtml,
      iconSize: [
        50,
        50
      ],
      iconAnchor: [
        25,
        50
      ],
      popupAnchor: [
        0,
        -50
      ]
    }));
  }, [currentSelected]);

  const handleCheckbox = (checked, projectSlug) => {
    if (!selectionLoading) {
      const updateType = (checked)
        ? "add"
        : "remove";

      setCurrentSelected(checked);
      setCheckboxDisabled(true);

      setChange({
        type: updateType,
        data: [projectSlug],
        source: "map"
      });
    }
  };

  useEffect(() => {
    const settingCheckboxDisabled = () => {
      const timeoutId = setTimeout(() => {
        setCheckboxDisabled(false);
      }, 250);

      return () => {
        clearTimeout(timeoutId);
      };
    };

    if (checkboxDisabled && !selectionLoading) {
      settingCheckboxDisabled();
    }
  }, [selectionLoading]);

  if (!icon) {
    return null;
  }

  return (
    <Marker
      position={[
        lat,
        lng
      ]}
      icon={icon}
      eventHandlers={{
        popupopen: () => {
          setOpened(true);
        },
        popupclose: () => {
          // setOpened(false);
        }
      }}
    >
      {(!isSimpleMap) &&
        <ProjectMarkerPopup {...{
          project,
          selected: simple || currentSelected,
          handleCheckbox: simple ? null : handleCheckbox,
          checkboxDisabled,
          opened,
          map,
          point
        }} />
      }

    </Marker>
  );
};

export default memo(ProjectMarker, () => false);
