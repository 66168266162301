import { priceLabels } from "~/src/modules/labels.js";
import {
  formatEuro, formatEuroHistoric, formatMonthDotSeparated
} from "~/src/modules/formatters";
import priceTerms from "~/src/lib/price-terms.js";

const {
  normal: normalTerm,
  investor: investmentTerm
} = priceTerms;

/**
 *
 * @param root0
 * @param root0.unit
 */
// eslint-disable-next-line max-lines-per-function
const UnitPrices = function ({ unit }) {
  const cleanHistoricPrices = function (historicPriceArray) {
    const countHistoricPricesWithoutDate = historicPriceArray
      .filter((historicPrice) => historicPrice.date === "");

    return (countHistoricPricesWithoutDate.length > 1)
      ? historicPriceArray.filter((historicPrice) => historicPrice.date !== "")
      : historicPriceArray;
  };

  const addHistoricPrices = function (entry, index) {
    return ((entry.date === "")
      ? (
        <div key={`historicPrice_${index}`} className="grid grid-cols-1">
          <p className="text-xs text-center">{formatEuroHistoric(parseFloat(entry.price))}</p>
        </div>
      )
      : (
        <div key={`historicPrice_${index}`} className="grid grid-cols-2 gap-1">
          <p className="text-xs text-right">{(entry.date) ? `bis ${formatMonthDotSeparated(entry.date)}:` : ""}</p>
          <p className="text-xs text-left">{formatEuroHistoric(parseFloat(entry.price))}</p>
        </div>
      ));
  };

  const offerPriceNormalHistory = cleanHistoricPrices(unit.offer_price_normal_history);
  const offerPriceInvestorHistory = cleanHistoricPrices(unit.offer_price_investor_history);
  const rentNettoHistory = cleanHistoricPrices(unit.rent_netto_history);
  const rentBkHistory = cleanHistoricPrices(unit.rent_bk_history);

  const offerPrices = [];

  const offerNormalValue = unit.offer_price_normal;
  const rentBaseValue = unit.rent_netto;

  if (Number.isFinite(offerNormalValue)) {
    offerPrices.push(<div>
      <p className="font-bold">{formatEuro(offerNormalValue)}</p>
      <p className="text-xs leading-none">{normalTerm}</p>
      {(offerPriceNormalHistory.length)
        ? (
          <>
            <p className="text-xs font-semibold pt-0.5">Preisentwicklung</p>
            {offerPriceNormalHistory.map(addHistoricPrices)}
          </>
        )
        : null
      }
    </div>);
  }

  const offerInvestmentValue = unit.offer_price_investor;

  if (Number.isFinite(offerInvestmentValue)) {
    offerPrices.push(<div>
      <p className="font-bold">{formatEuro(offerInvestmentValue)}</p>
      <p className="text-xs leading-none">{investmentTerm}</p>
      {(offerPriceInvestorHistory.length)
        ? (
          <>
            <p className="text-xs font-semibold pt-0.5">Preisentwicklung</p>
            {offerPriceInvestorHistory.map(addHistoricPrices)}
          </>
        )
        : null
      }
    </div>);
  }

  const rentPrices = [];

  if (Number.isFinite(rentBaseValue)) {
    rentPrices.push(<div>
      <p className="font-bold">{formatEuro(rentBaseValue)}</p>
      <p className="text-xs leading-none">{priceLabels.rentNet}</p>
      {(rentNettoHistory.length)
        ? (<>
          <p className="text-xs font-semibold pt-0.5">Preisentwicklung</p>
          {rentNettoHistory.map(addHistoricPrices)}
        </>)
        : null
      }
    </div>);
  }

  const rentTotal = unit.rent_bk;

  if (Number.isFinite(rentTotal)) {
    rentPrices.push(<div>
      <p className="font-bold">{formatEuro(rentTotal)}</p>
      <p className="text-xs leading-none">{priceLabels.rentTotal}</p>
      {(rentBkHistory.length)
        ? (<>
          <p className="text-xs font-semibold pt-0.5">Preisentwicklung</p>
          {rentBkHistory.map(addHistoricPrices)}
        </>)
        : null
      }
    </div>);
  }

  const prices = [];

  if (unit.buyable) {
    prices.push(...offerPrices);
  }

  if (unit.rentable) {
    prices.push(...rentPrices);
  }

  if (!prices.length) {
    prices.push(<p className="font-bold">-</p>);
  }

  return (
    <div className="p-2 text-sm text-center text-gray-500 bg-gray-200">
      <p>Preis</p>
      <div className="mt-0.5 space-y-3">{prices}</div>
    </div>
  );
};

export default UnitPrices;
